<template>
  <div class="flex flex-wrap justify-center md:justify-start">
    <div class="
    w-16 
    h-16 
    bg-cyan-400 
    hover:bg-cyan-700 
    transition 
    duration-500 
    ease-in-out 
    rounded-full 
    flex 
    flex-wrap 
    justify-center 
    content-center
    mb-2
    ">
      <font-awesome-icon :icon="icon" class="text-2xl text-white"/>
    </div>
  </div>
  <h3 class="uppercase font-bold text-cyan-400">{{ title }}</h3>
  <body-text :text=text></body-text>
</template>

<script>
export default {
  props: ['icon', 'title', 'text']
};
</script>