<template>
  <section class="bg-cyan-900 w-full p-20">
    <div class="flex flex-wrap justify-center">
      <img src="http://res.cloudinary.com/dt0kkz5sz/image/upload/c_scale,w_500/v1644865562/logo-anatel.png" alt="anatel" class="w-40">
    </div>
    <div class="pt-6 text-center text-white">
      <p class="text-sm">A Neofibra é uma empresa de telecomunicações com razão social L. E. GUIZUM BENSONI - COMUNICAÇÃO, inscrita no CNPJ 13.264.444/0001-86 com licença SCM da Anatel.</p>
      <p class="pt-4 text-xs">Rua São Paulo, 152-242 - Centro - Boa Esperança/PR - CEP 87390-000 - Telefone: 44 99921-0473 - contato@neofibra.net.br</p>
    </div>
  </section>
</template>

<script>
export default {};
</script>
