<template>
  <section class="grid grid-cols-2 bg-cyan-100 hover:bg-cyan-200 transition duration-500 ease-in-out gap-4 md:gap-12 h-48 md:h-60 lg:h-96" id="usage">
    <div class="grid-span-1">
      <img src="https://res.cloudinary.com/dt0kkz5sz/image/upload/c_scale,w_900/v1644865752/adv-talk_yru0df.jpg" alt="converse" class="rounded-br-3xl object-cover h-48 md:h-60 lg:h-96 w-full">
    </div>
    <div class="grid-span-1 flex flex-wrap content-center">
      <h1 class="text-base md:text-2xl lg:text-4xl px-2 md:px-4 lg:px-8 font-extralight text-left text-cyan-700 hover:text-cyan-500 transition duration-500 ease-in-out uppercase">converse com familiares ou a trabalho sem travamentos</h1>
    </div>
  </section>

  <section class="grid grid-cols-2 bg-cyan-400 hover:bg-cyan-500 transition duration-500 ease-in-out gap-4 md:gap-12 h-48 md:h-60 lg:h-96">
    <div class="grid-span-1 flex flex-wrap content-center">
      <h1 class="text-base md:text-2xl lg:text-4xl px-2 md:px-4 lg:px-8 text-right font-extralight text-cyan-100 hover:text-cyan-300 transition duration-500 ease-in-out uppercase">Filmes e Séries? velocidade para você não perder nada</h1>
    </div>
    <div class="grid-span-1">
      <img src="https://res.cloudinary.com/dt0kkz5sz/image/upload/c_scale,w_900/v1644865752/adv-stream_usjqy4.jpg" alt="carregamento rápido de vídeos sob demanda" class="rounded-tl-3xl rounded-bl-3xl object-cover h-48 md:h-60 lg:h-96 w-full">
    </div>
  </section>

  <section class="grid grid-cols-2 bg-cyan-800 hover:bg-cyan-900 transition duration-500 ease-in-out gap-4 md:gap-12 h-48 md:h-60 lg:h-96">
    <div class="grid-span-1">
      <img src="https://res.cloudinary.com/dt0kkz5sz/image/upload/c_scale,w_900/v1644865752/adv-games_akpl0j.jpg" alt="jogue em alta velocidade" class="rounded-tr-3xl object-cover h-48 md:h-60 lg:h-96 w-full">
    </div>
    <div class="grid-span-1 flex flex-wrap content-center">
      <h1 class="text-base md:text-2xl lg:text-4xl px-2 md:px-4 lg:px-8 font-extralight text-left text-cyan-400 hover:text-cyan-200 transition duration-500 ease-in-out uppercase">seus jogos e downloads em altíssima velocidade</h1>
    </div>
  </section>
</template>

<script>
export default {
  
}
</script>