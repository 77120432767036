<template>
  <h1 class="col-span-1 text-cyan-400 hover:text-cyan-700 transition duration-500 ease-in-out uppercase lg:text-7xl md:text-6xl text-4xl font-extralight mb-14 md:mb-0">
    {{ title }}
  </h1>
</template>

<script>
export default {
  props: ['title']
};
</script>