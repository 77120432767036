<template>
  <offcanvas-menu></offcanvas-menu>
  <div class="main" @click="closeMenu">
    <top-hero></top-hero>
    <advantages-section></advantages-section>
    <usage-section></usage-section>
    <business-section></business-section>
    <plans-section ref="plans"></plans-section>
    <footer-section></footer-section>
  </div>
</template>

<script>
import OffcanvasMenu from "@/components/OffcanvasMenu.vue";
import TopHero from "@/components/TopHero";
import AdvantagesSection from "@/components/AdvantagesSection.vue";
import UsageSection from "@/components/UsageSection.vue";
import BusinessSection from "@/components/BusinessSection.vue";
import PlansSection from "@/components/PlansSection.vue";
import FooterSection from "@/components/FooterSection.vue";

export default {
  name: "App",
  components: {
    OffcanvasMenu,
    TopHero,
    AdvantagesSection,
    UsageSection,
    BusinessSection,
    PlansSection,
    FooterSection,
  },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

#app {
  font-family: 'Ubuntu', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.main {
  margin-left: 64px;
  padding: 0;
}
</style>
