<template>
  <section>
    <div class="p-4 md:p-20 h-auto w-full plans-bg bg-center" id="plans">
      <h1 class="text-white uppercase text-3xl md:text-5xl font-light text-center">Planos que atendem suas necessidades</h1>
      <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 pt-20">
        <ul class="col-start-1 lg:col-start-2">
          <plans-card
            plan="20"
            price="69,90"
          >
          </plans-card>
          <plans-card
            plan="80"
            price="79,90"
          >
          </plans-card>
          <plans-card
            plan="300"
            price="99,90"
          >
          </plans-card>
          <plans-card
            plan="600"
            price="149,90"
          >
          </plans-card>
          <plans-card
            plan="900"
            price="199,90"
          >
          </plans-card>
          <div class="plans--card p-6 text-white">
          <ul>
            <li class="border-b-2 border-cyan-400 mb-2">
              <p>Infraestrutura em Fibra Ótica</p>
            </li>
            <li class="border-b-2 border-cyan-400 mb-2">
              <p>Garantia de velocidade de acesso</p>
            </li>
            <li class="border-b-2 border-cyan-400 mb-2">
              <p>Conexões com 100% de velocidade para download/upload</p>
            </li>
            <li class="border-b-2 border-cyan-400 mb-2">
              <p>Desconto aplicado de R$5,00 para pagamento até o vencimento</p>
            </li>
          </ul>
        </div>
        </ul>
      </div>
      
    </div>
  </section>
</template>

<script>
import PlansCard from './PlansCard.vue'
export default {
  components: { PlansCard },
}
</script>

<style scoped>
  .plans--card {
    background: rgba( 255, 255, 255, 0.2 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 8px );
    -webkit-backdrop-filter: blur( 8px );
    border-radius: 10px;
    border-top: 1px solid rgba( 255, 255, 255, 0.50 );
    border-left: 1px solid rgba( 255, 255, 255, 0.50 );
    transition: all .1s ease-in-out;
    -webkit-transition: all .2s ease-in-out;
    will-change: auto;
  }

  .plans-bg {
    background-image: url("https://res.cloudinary.com/dt0kkz5sz/image/upload/v1644935293/plans-bg-2.png");
    background-position: top center;
    background-size: cover;
  }
</style>