<template>
  <section class="py-20 md:py-40 grid grid-cols-4" id="advantages">
    <p
      class="
      col-start-1
      col-span-4
      md:leading-loose
      leading-loose
      lg:col-span-2
      lg:col-start-2
      md:col-span-4
      md:col-start-1
      text-gray-400
      text-center
      text-base
      md:text-xl
      px-6
      md:px-20
      ">
      {{ advantagesText }}
    </p>
  </section>
  <section class="grid grid-cols-1 md:grid-cols-2 lg:px-40 md:px-10 px-8 lg:gap-48 md:gap-24 pb-20">
    <h1 class="col-span-1 text-cyan-400 hover:text-cyan-700 transition duration-500 ease-in-out uppercase lg:text-7xl md:text-6xl text-4xl font-extralight text-center md:text-left mb-14 md:mb-0">
      Por que usar a Fibra Ótica Neofibra?
    </h1>
    <div class="col-span-1">
      <ul>
        <li class="mb-12 text-center md:text-left">
          <advantages-list
            icon="lock"
            title="segurança"
            text="Trabalhamos para garantir a confibialidade dos dados trafegados em nossa rede"
          >
          </advantages-list>
        </li>

        <li class="mb-12 text-center md:text-left">
          <advantages-list
            icon="headset"
            title="suporte técnico ágil"
            text="Contamos com uma equipe de profissionais treinados e preparados para melhor te atender"
          >
          </advantages-list>
        </li>

        <li class="mb-12 text-center md:text-left">
          <advantages-list
            icon="network-wired"
            title="tecnologia de ponta"
            text="Contamos com os melhores equipamentos do mercado para entregarmos a melhor conexão"
          >
          </advantages-list>
        </li>

        <li class="mb-12 text-center md:text-left">
          <advantages-list
            icon="balance-scale"
            title="estabilidade nos serviços"
            text="Contamos com uma infraestutura estável, projetada para manter a comunicação sempre operante"
          >
          </advantages-list>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import AdvantagesList from "@/components/AdvantagesList.vue";

export default {
  components: {
    AdvantagesList,
  },
  data() {
    return {
      advantagesText: 'A Neofibra é uma empresa de telecomunicações pioneira na entrega de links de alta performance e estabilidade para seus clientes. Temos orgulho de conectar pessoas ao mundo através da Internet e trabalhamos diariamente para tornar essa experiência única aos nossos clientes.'
    }
  }
  
}
</script>