<template>
  <section id="top-hero">
    <div class="w-full h-screen text-center bg-hero-img bg-cover bg-center p-4 pt-8 pb-12">
      <div class="h-1/6">
        <div class="bg-logo bg-contain bg-no-repeat md:w-72 w-44 h-14 md:h-28 float-left"></div>
        <a href="https://www.neofibratelecom.com.br/central_assinante_web/login" class="invisible md:visible float-right text-white hover:text-cyan-400 transition duration-500 ease-in-out text-xs font-black uppercase border-white hover:border-cyan-400 border-2 py-2 px-2 rounded-full">Central do Cliente</a>
      </div>
      <div class="h-3/6 flex flex-wrap content-center justify-center clear-both">
        <h3 class="text-white font-bold text-lg md:text-6xl">Acelerando suas conexões.<br>Acelerando sua vida.</h3>
      </div>
      <div class="h-1/6">
        <button v-scroll-to='"#plans"' class="uppercase text-xs md:text-base text-cyan-400 hover:text-cyan-100 font-bold border-cyan-400 hover:border-cyan-100 transition duration-500 ease-in-out border-4 py-3 px-6 rounded-full">conheça nossos planos</button>
      </div>
        <div class="flex flex-wrap content-center justify-center">
          <font-awesome-icon :icon="['fas', 'arrow-down']" class="text-white animate-bounce text-4xl" />
        </div>
    </div>
  </section>
</template>
  
<script>
export default {
  
}
</script>