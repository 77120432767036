<template>
  <menu
    ref="offcanvas"
    class="offcanvas-menu h-full fixed bg-gradient-to-r from-cyan-600 to-cyan-900 z-20 shadow-xl"
  >
    <span class="w-14 float-right h-full">
      <ul class="text-3xl text-center h-full py-16 flex flex-col justify-between">
        <li @click="openMenu()" :class="{active: isActive}">
          <font-awesome-icon :icon="['fas', 'bars']" class="icons hover:text-cyan-300 cursor-pointer"/>
        </li>
        <li>
          <a href="http://www.facebook.com/neofibraoficial" class="icons hover:text-cyan-300">
            <font-awesome-icon :icon="['fab', 'facebook-f']" />
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/neofibra.oficial/" class="icons hover:text-cyan-300">
            <font-awesome-icon :icon="['fab', 'instagram']" />
          </a>
        </li>
        <li>
          <a href="https://api.whatsapp.com/send?phone=554499210473" class="icons hover:text-cyan-300">
            <font-awesome-icon :icon="['fab', 'whatsapp']" />
          </a>
        </li>
      </ul>
    </span>
    <ul @click="closeMenu" :class="{active: isActive}" class="text-white py-20 text-left uppercase space-y-10 font-black pl-10">
      <li @click="closeMenu" :class="{active: isActive}" v-scroll-to="'#top-hero'" class="cursor-pointer"><figure class="bg-logo w-40 h-20 relative bg-contain bg-no-repeat text-center"></figure></li>
      <li @click="closeMenu" :class="{active: isActive}" v-scroll-to="'#advantages'" class="cursor-pointer">Vantagens</li>
      <li @click="closeMenu" :class="{active: isActive}" v-scroll-to="'#usage'" class="cursor-pointer">Acelerando sua vida</li>
      <li @click="closeMenu" :class="{active: isActive}" v-scroll-to="'#business'" class="cursor-pointer">Para sua Empresa</li>
      <li @click="closeMenu" :class="{active: isActive}" v-scroll-to='"#plans"' class="cursor-pointer">Nossos Planos</li>
      <li @click="closeMenu" :class="{active: isActive}"><a href="https://www.neofibratelecom.com.br/central_assinante_web/login">Central do Cliente</a></li>
    </ul>
  </menu>
</template>

<script>
export default {
  data() {
    return {
      isActive: true,
    }
  },
  methods: {
    
    openMenu: function() {
      this.$refs.offcanvas.style.left = "0px";
      this.isActive = !this.isActive;
      if (this.isActive) {
        this.closeMenu();
      }
    },
    closeMenu() {
      this.$refs.offcanvas.style.left = "-282px";
      this.isActive = true;
    }
  }
};
</script>

<style scoped>
menu {
  top: 0;
  bottom: 0;
  position: fixed;
  width: 350px;
  height: 100vh;
  left: -282px;
  margin: 0;
  padding: .3rem;
  overflow-x: hidden;
  transition: 0.3s;
}

.icons {
  @apply text-cyan-500;
  @apply transition;
  @apply duration-500;
  @apply ease-in-out;
}
</style>
