import { createApp } from "vue";
import App from "./App.vue";
import "./index.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faFontAwesome } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import BodyText from "@/components/BodyText.vue";
import TitleText from "@/components/TitleText.vue";

library.add(faFontAwesome);
library.add(fab);
library.add(fas);

const app = createApp(App);

var VueScrollTo = require("vue-scrollto");
app.use(VueScrollTo, {
  duration: 2000,
  easing: "ease",
});

app.component("font-awesome-icon", FontAwesomeIcon);
app.component("body-text", BodyText);
app.component("title-text", TitleText);

app.mount("#app");
