<template>
  <section class="py-20 md:py-40 grid md:grid-cols-2" id="business">
    <div class="relative h-96 md:h-screen flex flex-wrap content-center">
      <span class="absolute z-10 right-20 md:right-10">
        <img
          src="https://res.cloudinary.com/dt0kkz5sz/image/upload/c_scale,h_900/v1644865752/business-img.jpg"
          alt="Serviços para empresas"
          class="w-4/5 md:w-full h-1/3"
        />
      </span>
      <img 
        src="https://res.cloudinary.com/dt0kkz5sz/image/upload/c_scale,h_1200/v1644865752/business-bg_taymbq.jpg"
        alt="Serviços para empresas"
        class="w-1/2 md:w-4/5 h-full rounded-br-3xl rounded-tr-3xl object-cover"
      />
    </div>
    <div class="md:py-32 flex flex-wrap content-center">
      <title-text 
        :title=BusinessTitle
        class="text-center py-8 md:py-0"
      />
      <body-text
        :text=BusinessText
        class="text-center px-2 py-0 md:py-20"
      />
    </div>
  </section>
</template>

<script>

export default {
  data() {
    return {
      BusinessTitle: 'Sua empresa mais digital',
      BusinessText: 'Equipe de profissionais treinada para os principais serviços necessários para que sua empresa esteja presente no mundo digital'
    }
  }
}
</script>