<template>
  <section>
    <li class="plans--card backdrop-filter backdrop-blur-lg p-2 md:p-6 mb-2 shadow-2xl">
      <div class="grid grid-cols-4 gap-0">
        <div>
          <p class="text-white uppercase font-bold text-lg md:text-4xl text-left">{{ plan }}<span class="font-light capitalize text-sm md:text-xl">Mb</span></p>
        </div>
        <div class="col-span-2">
          <p class="text-white font-bold text-lg md:text-4xl uppercase text-center"><span class="font-light capitalize text-lg md:text-xl text-right">R$</span>{{ price }}<span class="font-light lowercase text-sm md:text-xl text-right">/mês</span></p>
        </div>
        <div>
          <a href="https://api.whatsapp.com/send?phone=554499210473" class="p-1 md:p-2 px-2 md:px-4 rounded-full border-2 border-white text-xs uppercase hover:bg-cyan-500 transition duration-500 ease-in-out text-white font-bold float-right">Assine</a>
        </div>
      </div>
    </li>
  </section>
</template>

<script>
export default {
  name: "PlansCard",
  props: ['plan', 'price']
}
</script>

<style scoped>
  .plans--card {
    background: rgba( 255, 255, 255, 0.2 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 8px );
    -webkit-backdrop-filter: blur( 8px );
    border-radius: 10px;
    border-top: 1px solid rgba( 255, 255, 255, 0.50 );
    border-left: 1px solid rgba( 255, 255, 255, 0.50 );
    transition: all .1s ease-in-out;
    -webkit-transition: all .2s ease-in-out;
    will-change: auto;
  }

</style>